import { showLoading, hideLoading } from 'react-redux-loading-bar';

import locale from '../utils/localization.json';
import {
    deletePointCloud,
    getPointClouds,
    uploadPointCloud,
} from '../services/pointcloudService';
import { shortString } from './../utils/string';
import { infoMessage, successMessage } from './../utils/message';

export const getAllPointClouds = () => {
    return async (dispatch) => {
        try {
            dispatch(showLoading());

            const statuses = ['inprogress', 'analyzed'];

            const { status, data } = await getPointClouds();
            if (status === 200) {
                const pointclouds = data.map(({ name, lastModified, url }) => {
                    let status = name
                        .split(/\.(?=[^.]+$)/)[0]
                        .split(/_(?=[^_]+$)/)[1];
                    let type;

                    if (statuses.includes(status)) {
                        type = name
                            .split(/\.(?=[^.]+$)/)[0]
                            .split(/_(?=[^_]+$)/)[0]
                            .split(/_(?=[^_]+$)/)[1];
                    } else {
                        type = status;
                        status = null;
                    }

                    return {
                        filename: name,
                        name: name
                            .split(/\.(?=[^.]+$)/)[0]
                            .split('_' + type)[0],
                        type,
                        status,
                        date: lastModified,
                        url,
                    };
                });

                await dispatch({
                    type: 'INIT_POINTCLOUD',
                    payload: pointclouds,
                });
            }
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const uploadNewPointCloud = (file, callback) => {
    return async (dispatch, getState) => {
        const lang = getState().language;

        const filename = file.get('files').name;
        const [name, type] = filename.split(/\.(?=[^.]+$)/);

        const body = new FormData();
        body.append('files', file.get('files'), `${name}_${type}.${type}`);

        try {
            infoMessage(
                `"${shortString(filename, 23)}" ${
                    locale[lang].uploadStart1
                }${'\n'}${locale[lang].uploadStart2}`
            );
            const config = {
                onUploadProgress: callback,
            };

            const { status } = await uploadPointCloud(body, config);

            if (status === 200) {
                successMessage(
                    `"${shortString(filename, 23)}" ${locale[lang].uploaded}`
                );
                dispatch(getAllPointClouds());
            }
        } catch {}
    };
};

export const handlePointCloudDelete = () => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const currentObject = getState().currentObject;

        try {
            const { status } = await deletePointCloud(currentObject.filename);

            if (status === 200) {
                successMessage(
                    `"${shortString(
                        `${currentObject.name}.${currentObject.type}`,
                        23
                    )}" ${locale[lang].deleted}`
                );
                dispatch(getAllPointClouds());
            }
        } catch {}
    };
};

export const updatePointCloudStatus = (filename) => {
    return async (dispatch, getState) => {
        const pointclouds = getState().pointclouds;
        const updatedPointclouds = [...pointclouds];
        const index = updatedPointclouds.findIndex(
            (p) => p.filename === filename
        );

        if (index >= 0) {
            updatedPointclouds[index].status = 'waiting';
        }

        await dispatch({
            type: 'UPDATE_POINTCLOUD',
            payload: updatedPointclouds,
        });
    };
};
